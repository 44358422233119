import Tab, { TabProps } from '@mui/material/Tab';
import { Link } from 'react-router-dom';

interface LinkTabProps extends TabProps {
  to: string;
}

const LinkTab = ({ label, to, ...props }: LinkTabProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Tab {...props} label={label} component={Link} to={to} />;
};

export default LinkTab;
