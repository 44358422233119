import ActionMap from 'common/types/ActionMap';

export enum AppActionTypes {
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
}

type AppPayload = {
  [AppActionTypes.SET_ACTIVE_TAB]: number;
};

export type AppStateType = {
  activeTab: number | 0;
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export const appReducer = (state: AppStateType, action: AppActions) => {
  switch (action.type) {
    case AppActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };
    default:
      return state;
  }
};
