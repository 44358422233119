export const appConfigs = {
  apiUrl: process.env.REACT_APP_API_URL
};

export const chatHub: string = 'chatHub';

export const defaultPageSizeOptions = [10, 20, 50, 100];

export const defaultDebounceTime = 600;

export const beforeTokenExpiryTimeInSecond = 180;
