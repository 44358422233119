import { lazy } from 'react';

import { Roles } from 'common/consts/roles';

// Anonymous routes
const ForgotPassword = lazy(() => import('features/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('features/auth/ResetPassword'));
const SignUp = lazy(() => import('features/auth/SignUp'));
const SignUpVerifyEmail = lazy(() => import('features/auth/SignUpVerifyEmail'));
const VerifyEmail = lazy(() => import('features/auth/VerifyEmail'));
const NotFound = lazy(() => import('features/auth/NotFound'));

// Admin routes
const DefaultPage = lazy(() => import('features/AdminApp/Default/'));
const UserList = lazy(() => import('features/AdminApp/UserManagement/UserListContainer'));
const UserDetail = lazy(() => import('features/AdminApp/UserManagement/UserDetailContainer'));
const OrderListContainer = lazy(() => import('features/AdminApp/OrderManagement/OrderListContainer'));
const OrderDetailContainer = lazy(() => import('features/AdminApp/OrderManagement/OrderDetailContainer'));
const LessonListContainer = lazy(() => import('features/AdminApp/LessonManagement/LessonListContainer'));
const LessonDetail = lazy(() => import('features/AdminApp/LessonManagement/LessonDetailContainer'));
const RankListContainer = lazy(() => import('features/AdminApp/RankManagement/RankListContainer'));
const ComputerListContainer = lazy(() => import('features/AdminApp/ComputerManagement/ComputerListContainer'));
const ComputerDetailContainer = lazy(() => import('features/AdminApp/ComputerManagement/ComputerDetailContainer'));

// User routes
const Login = lazy(() => import('features/UserApp/Login'));
const UserAppOrderListContainer = lazy(() => import('features/UserApp/OrderManagement/OrderListContainer'));
const CustomerOrderDetailContainer = lazy(
  () => import('features/UserApp/OrderManagement/CustomerOrderDetailContainer')
);
const Profile = lazy(() => import('features/UserApp/Profile'));
const WorkingHistory = lazy(() => import('features/UserApp/WorkingHistory'));
const EngineerLessonsContainer = lazy(() => import('features/UserApp/EngineerLessons/EngineerLessonsContainer'));
const JobBoardOrderList = lazy(() => import('features/UserApp/JobBoard/OrderListContainer'));
const EngineerScoreAndFeedbackDetail = lazy(() => import('features/UserApp/ScoreAndFeedback'));
const EngineerOrderDetail = lazy(() => import('features/UserApp/EngineerOrderDetail'));

// common routes
const ChangePassword = lazy(() => import('features/ChangePassword/ChangePasswordContainer'));

export const ANONYMOUS_ROUTES = {
  Login: {
    path: '',
    component: Login
  },
  ForgotPassword: {
    path: '/forgot-password',
    component: ForgotPassword
  },
  ResetPassword: {
    path: '/set-password',
    component: ResetPassword
  },
  SignUp: {
    path: '/sign-up',
    component: SignUp
  },
  SignUpVerifyEmail: {
    path: '/sign-up-verify-email',
    component: SignUpVerifyEmail
  },
  VerifyEmail: {
    path: '/verify-email',
    component: VerifyEmail
  },
  NotFound: {
    path: '*',
    component: NotFound
  }
};

export const ADMIN_APP_ROUTES = {
  Default: {
    path: '/admin',
    component: DefaultPage,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  UserDetails: {
    path: '/admin/users/:id',
    component: UserDetail,
    allowedRoles: [Roles.Admin]
  },
  UserCreate: {
    path: '/admin/users/new',
    component: UserDetail,
    allowedRoles: [Roles.Admin]
  },
  UserList: {
    path: '/admin/users',
    component: UserList,
    allowedRoles: [Roles.Admin]
  },
  LessonList: {
    path: '/admin/lessons',
    component: LessonListContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  LessonDetail: {
    path: '/admin/lessons/:id',
    component: LessonDetail,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  LessonCreate: {
    path: '/admin/lessons/new',
    component: LessonDetail,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  OrderList: {
    path: '/admin/orders',
    component: OrderListContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  RankList: {
    path: '/admin/ranks',
    component: RankListContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  ComputerList: {
    path: '/admin/computers',
    component: ComputerListContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  ComputerNew: {
    path: '/admin/computers/new',
    component: ComputerDetailContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  ComputerDetail: {
    path: '/admin/computers/:id',
    component: ComputerDetailContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  OrderNew: {
    path: '/admin/orders/new',
    component: OrderDetailContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  OrderDetail: {
    path: '/admin/orders/:id',
    component: OrderDetailContainer,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  Profile: {
    path: '/admin/profile',
    component: UserDetail,
    allowedRoles: [Roles.Admin, Roles.Manager]
  },
  ChangePassword: {
    path: '/admin/change-password',
    component: ChangePassword,
    allowedRoles: [Roles.Admin, Roles.Manager]
  }
};

export const USER_APP_ROUTES = {
  OrderList: {
    path: '/orders',
    component: UserAppOrderListContainer,
    allowedRoles: [Roles.Customer]
  },
  CustomerOrderDetailNew: {
    path: '/orders/new',
    component: CustomerOrderDetailContainer,
    allowedRoles: [Roles.Customer]
  },
  CustomerOrderDetail: {
    path: '/orders/:id',
    component: CustomerOrderDetailContainer,
    allowedRoles: [Roles.Customer]
  },
  Profile: {
    path: '/profile',
    component: Profile,
    allowedRoles: [Roles.Customer, Roles.Engineer]
  },
  ChangePassword: {
    path: '/change-password',
    component: ChangePassword,
    allowedRoles: [Roles.Customer, Roles.Engineer]
  },
  WorkingHistory: {
    path: '/working-history',
    component: WorkingHistory,
    allowRoles: [Roles.Engineer]
  },
  Lessons: {
    path: '/lessons',
    component: EngineerLessonsContainer,
    allowRoles: [Roles.Engineer]
  },
  JobBoard: {
    path: '/job-board',
    component: JobBoardOrderList,
    allowRoles: [Roles.Engineer]
  },
  JobBoardOrderDetail: {
    path: '/job-board/:id',
    component: EngineerOrderDetail,
    allowRoles: [Roles.Engineer]
  },
  WorkingHistoryOrderDetail: {
    path: '/working-history/:id',
    component: EngineerOrderDetail,
    allowedRoles: [Roles.Engineer]
  },
  ScoreAndFeedback: {
    path: '/working-history/:id/score-and-feedback',
    component: EngineerScoreAndFeedbackDetail,
    allowedRoles: [Roles.Engineer]
  }
};
