import { useCallback } from 'react';

import { Avatar, Box, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';

type UserSearchItemProps = {
  userEmail: string;
  userName: string;
  lastOnlineTimestamp?: number | null;
  handleSelectOnSearchItem: (userEmail: string, userName: string, lastOnlineTimestamp?: number | null) => void;
};

const UserSearchItem = ({
  userEmail,
  userName,
  lastOnlineTimestamp,
  handleSelectOnSearchItem
}: UserSearchItemProps) => {
  const handleOnClicked = useCallback(() => {
    handleSelectOnSearchItem(userEmail, userName, lastOnlineTimestamp);
  }, [handleSelectOnSearchItem, lastOnlineTimestamp, userEmail, userName]);
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={1}
      onClick={handleOnClicked}
      sx={{
        paddingLeft: 1,
        py: 1,
        '&:hover': {
          backgroundColor: '#0f520d',
          borderRadius: '12px',
          cursor: 'pointer'
        }
      }}
    >
      <Avatar sx={{ bgcolor: yellow[300], width: 18, height: 18, px: 2, py: 2 }}>{userName.toUpperCase()[0]}</Avatar>
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: '192px', flexGrow: 1 }}
      >
        <Box
          component="div"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.2,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            paddingRight: 2
          }}
        >
          <Typography variant="h5" color="white" fontWeight="400" noWrap maxWidth="164px">
            {userName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserSearchItem;
