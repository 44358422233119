import { produce } from 'immer';

import {
  IActiveChatUser,
  IChatConversation,
  IChatManagerState,
  IMarkingErrorSendingMessage,
  IMessageSending,
  IMessageSent,
  IReceivedMessage,
  ISelectedUser
} from './types';

export type ChatManagerActionType =
  | { type: 'chatManager.messageReceived'; payload: IReceivedMessage }
  | { type: 'chatManager.messageSent'; payload: IMessageSent }
  | { type: 'chatManager.messageSending'; payload: IMessageSending }
  | { type: 'chatManager.selectingUser'; payload: ISelectedUser }
  | { type: 'chatManager.markReadConversation'; payload: string }
  | { type: 'chatManager.markErrorSendingMessage'; payload: IMarkingErrorSendingMessage }
  | { type: 'chatManager.messageResending'; payload: IMessageSending }
  | { type: 'chatManager.isTyping'; payload: string }
  | { type: 'chatManager.updateChatActiveUsers'; payload: IActiveChatUser };

export const initChatManagerStateState: IChatManagerState = {
  isLoading: false,
  selectedUser: undefined,
  conversations: [],
  unReadConversations: 0,
  typingUsers: undefined,
  activeChatUsers: []
};

export const chatManagerReducer = (state: IChatManagerState, action: ChatManagerActionType): IChatManagerState => {
  switch (action.type) {
    case 'chatManager.messageReceived': {
      return produce(state, (draft) => {
        const newState = HandleMessageReceived(state, action.payload);
        draft.conversations = newState.conversations.slice().sort((a, b) => b.lastUpdated - a.lastUpdated);
        draft.unReadConversations = newState.conversations.filter((con) => con.unreadMessages > 0).length;
        draft.lastReceivedMessageId = action.payload.id;
        draft.lastUpdatedUser = action.payload.userEmail;
      });
    }
    case 'chatManager.messageSent': {
      return produce(state, (draft) => {
        const newState = HandleMessageSent(state, action.payload);
        draft.conversations = newState.conversations.slice().sort((a, b) => b.lastUpdated - a.lastUpdated);
        draft.unReadConversations = newState.conversations.filter((con) => con.unreadMessages > 0).length;
        draft.lastUpdatedUser = action.payload.toUserEmail;
      });
    }
    case 'chatManager.messageSending': {
      return produce(state, (draft) => {
        const newState = HandleMessageSending(state, action.payload);
        draft.conversations = newState.conversations.slice().sort((a, b) => b.lastUpdated - a.lastUpdated);
        draft.unReadConversations = newState.conversations.filter((con) => con.unreadMessages > 0).length;
        draft.lastUpdatedUser = action.payload.toUserEmail;
      });
    }
    case 'chatManager.messageResending': {
      return produce(state, (draft) => {
        const newState = HandleMessageResending(state, action.payload);
        draft.conversations = newState.conversations.slice().sort((a, b) => b.lastUpdated - a.lastUpdated);
        draft.unReadConversations = newState.conversations.filter((con) => con.unreadMessages > 0).length;
        draft.lastUpdatedUser = action.payload.toUserEmail;
      });
    }
    case 'chatManager.selectingUser': {
      return produce(state, (draft) => {
        const newConversations = draft.conversations.map((con) => {
          if (con.email === action.payload.userEmail) {
            return {
              ...con,
              unreadMessages: 0
            };
          } else {
            return con;
          }
        });

        draft.selectedUser = action.payload;
        draft.conversations = [...newConversations];
        draft.unReadConversations = newConversations.filter((con) => con.unreadMessages > 0).length;
      });
    }
    case 'chatManager.markReadConversation': {
      return produce(state, (draft) => {
        const newConversations = draft.conversations.map((con) => {
          if (con.email === action.payload) {
            return {
              ...con,
              unreadMessages: 0
            };
          } else {
            return con;
          }
        });
        draft.conversations = [...newConversations];
        draft.unReadConversations = newConversations.filter((con) => con.unreadMessages > 0).length;
      });
    }
    case 'chatManager.markErrorSendingMessage': {
      return produce(state, (draft) => {
        draft.conversations = draft.conversations.map((con) => {
          if (con.email === action.payload.toUserEmail) {
            return {
              ...con,
              messages: con.messages.map((mess) => {
                if (mess.id === action.payload.id) {
                  return {
                    ...mess,
                    error: action.payload.error,
                    isSending: false
                  };
                } else {
                  return mess;
                }
              })
            };
          } else {
            return con;
          }
        });
      });
    }
    case 'chatManager.isTyping': {
      return produce(state, (draft) => {
        if (draft.typingUsers) {
          const userIndex = draft.typingUsers.findIndex((u) => u.userEmail == action.payload);
          if (userIndex !== -1) {
            draft.typingUsers[userIndex].typingTimestamp = new Date().getTime();
          } else {
            draft.typingUsers.push({ userEmail: action.payload, typingTimestamp: new Date().getTime() });
          }
        } else {
          draft.typingUsers = [{ userEmail: action.payload, typingTimestamp: new Date().getTime() }];
        }
      });
    }
    case 'chatManager.updateChatActiveUsers': {
      return produce(state, (draft) => {
        const uIndex = draft.activeChatUsers.findIndex((u) => u.email === action.payload.email);
        if (uIndex !== -1) {
          draft.activeChatUsers[uIndex].lastSignalTimestamp = action.payload.lastSignalTimestamp;
        } else {
          draft.activeChatUsers.push({
            email: action.payload.email,
            lastSignalTimestamp: action.payload.lastSignalTimestamp
          });
        }
      });
    }
    default:
      throw new Error();
  }
};

const HandleMessageReceived = (state: IChatManagerState, payload: IReceivedMessage) => {
  const newState: IChatManagerState = { ...state };
  const updated = new Date().getTime();
  const conversation = state.conversations.find((con) => con.email === payload.userEmail);
  if (conversation) {
    const newConversations: IChatConversation[] = newState.conversations.map((con) => {
      if (con.email === conversation.email) {
        return {
          ...con,
          unreadMessages: con.unreadMessages + 1,
          messages: [
            ...con.messages,
            {
              id: payload.id,
              side: 'left',
              message: payload.message,
              sentDate: payload.receivedDate,
              fromUserName: payload.userName,
              fromUserEmail: payload.userEmail,
              isSending: false
            }
          ],
          lastMessage: {
            id: payload.id,
            side: 'left',
            message: payload.message,
            sentDate: payload.receivedDate,
            fromUserName: payload.userName,
            fromUserEmail: payload.userEmail,
            isSending: false
          },
          lastUpdated: updated
        };
      } else {
        return con;
      }
    });
    newState.conversations = [...newConversations];
  } else {
    newState.conversations = [
      ...newState.conversations,
      {
        email: payload.userEmail,
        lastMessage: {
          id: payload.id,
          side: 'left',
          message: payload.message,
          sentDate: payload.receivedDate,
          fromUserName: payload.userName,
          fromUserEmail: payload.userEmail,
          isSending: false
        },
        unreadMessages: 1,
        messages: [
          {
            id: payload.id,
            side: 'left',
            message: payload.message,
            sentDate: payload.receivedDate,
            fromUserName: payload.userName,
            fromUserEmail: payload.userEmail,
            isSending: false
          }
        ],
        userName: payload.userName,
        lastUpdated: updated
      }
    ];
  }
  return newState;
};

const HandleMessageSending = (state: IChatManagerState, payload: IMessageSending) => {
  const newState: IChatManagerState = { ...state };
  const updated: number = new Date().getTime();
  const conversation = state.conversations.find((con) => con.email === payload.toUserEmail);
  if (conversation) {
    const newConversations: IChatConversation[] = newState.conversations.map((con) => {
      if (con.email === conversation.email) {
        return {
          ...con,
          unreadMessages: 0,
          messages: [
            ...con.messages,
            {
              id: payload.id,
              side: 'right',
              message: payload.message,
              sentDate: undefined,
              fromUserName: payload.toUserName,
              fromUserEmail: payload.fromUserEmail,
              isSending: true
            }
          ],
          lastMessage: {
            id: payload.id,
            side: 'right',
            message: payload.message,
            sentDate: undefined,
            fromUserName: payload.toUserName,
            fromUserEmail: payload.fromUserEmail,
            isSending: true
          },
          lastUpdated: updated
        };
      } else {
        return con;
      }
    });
    newState.conversations = [...newConversations];
  } else {
    newState.conversations = [
      ...newState.conversations,
      {
        email: payload.toUserEmail,
        lastMessage: {
          id: payload.id,
          side: 'right',
          message: payload.message,
          sentDate: undefined,
          fromUserName: payload.toUserName,
          fromUserEmail: payload.fromUserEmail,
          isSending: true
        },
        unreadMessages: 0,
        messages: [
          {
            id: payload.id,
            side: 'right',
            message: payload.message,
            sentDate: undefined,
            fromUserName: payload.toUserName,
            fromUserEmail: payload.fromUserEmail,
            isSending: true
          }
        ],
        userName: payload.toUserName,
        lastUpdated: updated
      }
    ];
  }
  return newState;
};

const HandleMessageSent = (state: IChatManagerState, payload: IMessageSent) => {
  const newState: IChatManagerState = { ...state };
  const updated: number = new Date().getTime();
  const conversation = state.conversations.find((con) => con.email === payload.toUserEmail);
  if (conversation) {
    const newConversations: IChatConversation[] = newState.conversations.map((con) => {
      if (con.email === conversation.email) {
        const messageIdx = con.messages.findIndex((mess) => mess.id === payload.id);
        if (messageIdx != -1) {
          return {
            ...con,
            messages: con.messages.map((message) => {
              if (message.id === payload.id) {
                return {
                  ...message,
                  sentDate: payload.sentDate,
                  isSending: false
                };
              } else {
                return message;
              }
            }),
            lastUpdated: updated
          };
        }
        return {
          ...con,
          messages: [
            ...con.messages,
            {
              id: payload.id,
              side: 'right',
              message: payload.message,
              sentDate: payload.sentDate,
              fromUserName: payload.toUserName,
              fromUserEmail: payload.fromUserEmail,
              isSending: false
            }
          ],
          lastUpdated: updated,
          lastMessage: {
            id: payload.id,
            side: 'right',
            message: payload.message,
            sentDate: payload.sentDate,
            fromUserName: payload.toUserName,
            fromUserEmail: payload.fromUserEmail,
            isSending: false
          }
        };
      } else {
        return con;
      }
    });
    newState.conversations = [...newConversations];
  } else {
    newState.conversations = [
      ...newState.conversations,
      {
        email: payload.toUserEmail,
        unreadMessages: 0,
        messages: [
          {
            id: payload.id,
            side: 'right',
            message: payload.message,
            sentDate: payload.sentDate,
            fromUserName: payload.toUserName,
            fromUserEmail: payload.fromUserEmail,
            isSending: false
          }
        ],
        userName: payload.toUserName,
        lastUpdated: updated,
        lastMessage: {
          id: payload.id,
          side: 'right',
          message: payload.message,
          sentDate: payload.sentDate,
          fromUserName: payload.toUserName,
          fromUserEmail: payload.fromUserEmail,
          isSending: false
        }
      }
    ];
  }

  return newState;
};

const HandleMessageResending = (state: IChatManagerState, payload: IMessageSending) => {
  const newState: IChatManagerState = { ...state };
  const updated: number = new Date().getTime();
  const conversation = state.conversations.find((con) => con.email === payload.toUserEmail);
  if (conversation) {
    const newConversations: IChatConversation[] = newState.conversations.map((con) => {
      if (con.email === conversation.email) {
        const removed = con.messages.filter((mess) => mess.id !== payload.id);
        return {
          ...con,
          unreadMessages: 0,
          messages: [
            ...removed,
            {
              id: payload.id,
              side: 'right',
              message: payload.message,
              sentDate: undefined,
              fromUserName: payload.toUserName,
              fromUserEmail: payload.fromUserEmail,
              isSending: true
            }
          ],
          lastMessage: {
            id: payload.id,
            side: 'right',
            message: payload.message,
            sentDate: undefined,
            fromUserName: payload.toUserName,
            fromUserEmail: payload.fromUserEmail,
            isSending: true
          },
          lastUpdated: updated
        };
      } else {
        return con;
      }
    });
    newState.conversations = [...newConversations];
  }

  return newState;
};
