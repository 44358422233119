import { useMemo, useState } from 'react';

import { AccountCircle } from '@mui/icons-material';
import { Button, Menu, MenuItem, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'app/AppContext';
import { ANONYMOUS_ROUTES } from 'app/AppRoutes';
import { AppActionTypes } from 'app/reducers/appReducer';

import { Roles } from 'common/consts/roles';

import { AuthActionTypes } from 'features/auth/login-reducer';

import LinkTab from '../LinkTab';
import Header from './Header';

export default function UserAppHeader() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    dispatch,
    state: {
      auth: { info: authInfo },
      app: { activeTab: activeTab }
    }
  } = useAppContext();

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goChangePassword = async () => {
    navigate('/change-password');
    handleClose();
  };

  const handleLogout = async () => {
    dispatch({ type: AuthActionTypes.AUTH_LOG_OUT });
    return navigate(`/${ANONYMOUS_ROUTES.Login.path}`, { replace: true });
  };

  const goProfile = async () => {
    navigate('/profile');
    handleClose();
  };

  const name = authInfo ? `${authInfo.firstName} ${authInfo.lastName}` : 'User';

  const navItems = useMemo(() => {
    if (authInfo?.role === Roles.Engineer) {
      return [
        { label: 'Works', to: '/working-history' },
        { label: 'Job Board', to: '/job-board' },
        { label: 'Lessons', to: '/lessons' }
      ];
    }

    if (authInfo?.role === Roles.Customer) {
      return [{ label: 'Orders', to: '/orders' }];
    }

    return [];
  }, [authInfo?.role]);

  const handleChangeTab = (e: any, value: number) => {
    dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: value });
  };

  return (
    <Header>
      <Tabs value={activeTab} aria-label="User nav tabs" textColor="inherit" onChange={handleChangeTab}>
        {navItems.map((item) => (
          <LinkTab key={item.to} label={item.label} to={item.to} />
        ))}
      </Tabs>

      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        startIcon={<AccountCircle />}
        sx={{ maxWidth: '300px' }}
      >
        <Typography
          sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#FFF' }}
          component="span"
        >
          {name}
        </Typography>
      </Button>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={goProfile}>{t('profile')}</MenuItem>
        <MenuItem onClick={goChangePassword}>{t('changePassword')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
      </Menu>
    </Header>
  );
}
