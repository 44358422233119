import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';

type MessageReSendActionProps = {
  error?: string | undefined;
  isSending?: boolean | undefined;
  handleOnResendClicked: () => void;
};

const MessageReSendAction = ({ error, isSending, handleOnResendClicked }: MessageReSendActionProps) => {
  if (!error) {
    return isSending ? <CircularProgress size={12} /> : <></>;
  } else {
    return (
      <Tooltip title={error} placement="left">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleOnResendClicked();
          }}
        >
          <ErrorOutlineIcon sx={{ width: '16px', height: '16px', color: 'red' }} />
        </IconButton>
      </Tooltip>
    );
  }
};

export default MessageReSendAction;
