import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios, { CancelTokenSource } from 'axios';
import { useDebounce } from 'react-use';

import { userClient } from 'apis';

import { defaultDebounceTime } from 'common/consts/configs';

import { useChatManagerContext } from '../context';
import { SearchInput } from './CustomStyledSearchBar';
import UserCardItem from './UserCardItem';
import UserSearchItem from './UserSearchItem';

type UserSearchItemResponse = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  lastOnlineTimestamp?: number | null;
};

const initQuery = {
  search: '',
  pageNumber: 1,
  pageSize: 5
};

const ChatNavigationBar = () => {
  const {
    state: { conversations: conversations },
    dispatchChatManager
  } = useChatManagerContext();
  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState<{ search: string | undefined; pageNumber: number; pageSize: number }>({
    ...initQuery
  });
  const [onSearchMode, setOnSearchMode] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [users, setUsers] = useState<UserSearchItemResponse[]>([]);
  const onSearchTextChanged = useCallback((e: any) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    setIsTyping(true);
    if (newValue !== '' && newValue !== ' ' && newValue) {
      setOnSearchMode(true);
    } else {
      setOnSearchMode(false);
      setUsers([]);
    }
  }, []);

  const searchUsers = useCallback(
    async (source: CancelTokenSource) => {
      if (query.search && query.search !== ' ') {
        setIsSearching(true);
        const results = await userClient.searchUsersWithPagination(
          query.search,
          query.pageNumber,
          query.pageSize,
          undefined,
          source.token
        );
        setIsSearching(false);
        if (results.items) {
          const featchedUsers: UserSearchItemResponse[] = results.items.map((u) => {
            const user: UserSearchItemResponse = {
              id: u.id || '',
              email: u.email || '',
              firstName: u.firstName || '',
              lastName: u.lastName || '',
              lastOnlineTimestamp: u.lastOnlineTimestamp
            };
            return user;
          });
          setUsers(featchedUsers);
        }
      }
    },
    [query]
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    searchUsers(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, [searchUsers]);

  useDebounce(
    () => {
      setIsTyping(false);
      if (searchText && searchText !== ' ') {
        setQuery({
          ...query,
          search: searchText
        });
      }
    },
    defaultDebounceTime,
    [searchText]
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSelectOnSearchItem = useCallback(
    (userEmail: string, userName: string, lastOnlineTimestamp?: number | null) => {
      setSearchText('');
      setOnSearchMode(false);
      setQuery(initQuery);
      if (searchInputRef && searchInputRef.current) {
        searchInputRef.current.blur();
      }
      dispatchChatManager({
        type: 'chatManager.selectingUser',
        payload: {
          userEmail: userEmail,
          userName: userName,
          lastOnlineTimestamp: lastOnlineTimestamp
        }
      });
    },
    [dispatchChatManager]
  );

  return (
    <Box component="div" sx={{ overflowY: 'auto', height: '100%' }}>
      <Box sx={{ py: 1, px: 1 }}>
        <SearchInput
          id="search-input"
          inputRef={searchInputRef}
          placeholder="Search for users..."
          onChange={onSearchTextChanged}
          value={searchText}
        />
      </Box>

      {isSearching && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py: 0.6 }}>
          <CircularProgress size={20} />
        </Box>
      )}
      {!onSearchMode &&
        conversations &&
        conversations.map((con) => (
          <UserCardItem
            userEmail={con.email}
            lastMessage={con.lastMessage}
            userName={con.userName}
            key={con.userName}
            unreadMessage={con.unreadMessages}
          />
        ))}
      {!onSearchMode && conversations.length < 1 && (
        <Box sx={{ display: 'flex', py: 2, px: 2, alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h4" color="#fff" fontWeight="light">
            No conversations
          </Typography>
        </Box>
      )}
      {onSearchMode &&
        users &&
        users.map((user) => (
          <UserSearchItem
            userEmail={user.email}
            userName={`${user.firstName}.${user.lastName}`}
            lastOnlineTimestamp={user.lastOnlineTimestamp}
            key={user.email}
            handleSelectOnSearchItem={handleSelectOnSearchItem}
          />
        ))}
      {!isSearching && !isTyping && onSearchMode && (!users || users.length < 1) && (
        <Box sx={{ display: 'flex', py: 2, px: 2, alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h4" color="#fff" fontWeight="light">
            No results found
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChatNavigationBar;
