import { ADMIN_APP_ROUTES } from 'app/AppRoutes';
import { matchRoutes, useLocation } from 'react-router-dom';

const RouteKeys = Object.keys(ADMIN_APP_ROUTES);

const ADMIN_APP_ROUTES_ITEMS = RouteKeys.map((key) => ADMIN_APP_ROUTES[key as keyof typeof ADMIN_APP_ROUTES]);
const useCurrentAdminAppRoute = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(ADMIN_APP_ROUTES_ITEMS, location);

  if (matchedRoutes && matchedRoutes[0]) {
    return matchedRoutes[0].route;
  }
  return null;
};

export default useCurrentAdminAppRoute;
