import { useEffect, useState } from 'react';

import { HubConnection } from '@microsoft/signalr';
import { Box, Typography } from '@mui/material';

import { ISelectedUser } from '../types';

type ChatBoxHeaderOnlineIndicatorProps = {
  selectedUser: ISelectedUser;
  lastTrackedOnlineTimeStamp?: number | null;
  isTemporarySelected?: boolean;
  chatConnection: HubConnection | null;
  handleUpdatingIsOnline: (isOnline: boolean) => void;
};

const ChatBoxHeaderOnlineIndicator = ({
  selectedUser,
  lastTrackedOnlineTimeStamp,
  isTemporarySelected,
  chatConnection,
  handleUpdatingIsOnline
}: ChatBoxHeaderOnlineIndicatorProps) => {
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [lastestSelectedUserOnlineTimestamp, setLastestSelectedUserOnlineTimestamp] = useState<number | null>(null);

  useEffect(() => {
    // lastTrackedOnlineTimeStamp => for user that is subscribed to online status
    // lastestSelectedUserOnlineTimestamp => latest status that get from server by interval
    // selectedUser.lastOnlineTimestamp => a temporary online timestamp that get from server when clicked on an user search item

    const lastSignalTimestamp =
      lastTrackedOnlineTimeStamp || lastestSelectedUserOnlineTimestamp || selectedUser?.lastOnlineTimestamp;

    if (lastSignalTimestamp && new Date().getTime() - lastSignalTimestamp < 90000) {
      setIsOnline(true);
      handleUpdatingIsOnline(true);

      const timeout = setTimeout(() => {
        setIsOnline(false);
        handleUpdatingIsOnline(false);
      }, 90000);
      return () => {
        clearTimeout(timeout);
        setIsOnline(false);
        handleUpdatingIsOnline(false);
      };
    }
  }, [
    selectedUser?.lastOnlineTimestamp,
    lastestSelectedUserOnlineTimestamp,
    lastTrackedOnlineTimeStamp,
    handleUpdatingIsOnline
  ]);

  useEffect(() => {
    if (selectedUser?.userEmail && isTemporarySelected && chatConnection && chatConnection.state === 'Connected') {
      const subscribeFuncInstance = (timeStamp: number | null) => {
        setLastestSelectedUserOnlineTimestamp(timeStamp);
      };
      chatConnection.on(selectedUser.userEmail, subscribeFuncInstance);
      return () => {
        if (chatConnection && chatConnection.state === 'Connected') {
          chatConnection.off(selectedUser.userEmail, subscribeFuncInstance);
        }
      };
    }
  }, [chatConnection, isTemporarySelected, selectedUser.userEmail]);

  if (isOnline) {
    return (
      <Box
        component="div"
        sx={{
          borderRadius: '14px',
          backgroundColor: '#fff',
          py: 0.1,
          px: 1,
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2px'
        }}
      >
        <Box
          sx={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#4AAC5F'
          }}
        ></Box>
        <Typography fontSize="12px" color="#3c8c41">
          Online
        </Typography>
      </Box>
    );
  }
  return (
    <Typography variant="h6" color="#fff" fontWeight="light">
      Offline
    </Typography>
  );
};

export default ChatBoxHeaderOnlineIndicator;
