import { forwardRef } from 'react';

import ChatIcon from '@mui/icons-material/Chat';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { Box, Typography, Zoom } from '@mui/material';

type ChatWaiterIconProps = {
  unReadConversations: number;
};

const ChatWaiterIcon = forwardRef<HTMLInputElement, ChatWaiterIconProps>(
  ({ unReadConversations }: ChatWaiterIconProps, ref) => {
    return (
      <div ref={ref} style={{ display: 'flex', justifyContent: 'center' }}>
        {unReadConversations > 0 ? (
          <>
            <Zoom in={unReadConversations > 0}>
              <MarkUnreadChatAltIcon color="success" sx={{ minHeight: '44px', minWidth: '44px' }} />
            </Zoom>

            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: 'red',
                top: 8,
                left: 8
              }}
            >
              <Typography color="white" fontSize="11px" fontWeight="bold">
                {unReadConversations}
              </Typography>
            </Box>
          </>
        ) : (
          <Zoom in={unReadConversations <= 0}>
            <ChatIcon color="action" sx={{ minHeight: '44px', minWidth: '44px' }} />
          </Zoom>
        )}
      </div>
    );
  }
);

export default ChatWaiterIcon;
