import { useState } from 'react';

import { AccountCircle } from '@mui/icons-material';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'app/AppContext';
import { AppActionTypes } from 'app/reducers/appReducer';

import { AuthActionTypes } from 'features/auth/login-reducer';

import AdminNavTabs from './AdminNavTabs';
import Header from './Header';

export default function AdminHeader() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    dispatch,
    state: {
      auth: { info: authInfo }
    }
  } = useAppContext();

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goChangePassword = async () => {
    navigate('/admin/change-password');
    handleClose();
  };

  const handleLogout = async () => {
    dispatch({ type: AuthActionTypes.AUTH_LOG_OUT });
    dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 0 });
    return navigate('/', { replace: true });
  };

  const goProfile = async () => {
    navigate('/admin/profile');
    handleClose();
  };

  const name = authInfo ? `${authInfo.firstName} ${authInfo.lastName}` : 'Admin';

  return (
    <Header>
      <AdminNavTabs />
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        startIcon={<AccountCircle />}
        sx={{ maxWidth: '300px' }}
      >
        <Typography
          sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#FFF' }}
          component="span"
        >
          {name}
        </Typography>
      </Button>

      <Menu id="menu-appbar" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={goProfile}>{t('profile')}</MenuItem>
        <MenuItem onClick={goChangePassword}>{t('changePassword')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
      </Menu>
    </Header>
  );
}
