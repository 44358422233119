import { useCallback, useState } from 'react';

import { HubConnection } from '@microsoft/signalr';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';

import { useChatManagerContext } from '../context';
import ChatBoxHeaderOnlineIndicator from './ChatBoxHeaderOnlineIndicator';

type ChatBoxHeaderProps = {
  chatConnection: HubConnection | null;
};

const ChatBoxHeader = ({ chatConnection }: ChatBoxHeaderProps) => {
  const {
    state: { selectedUser: selectedUser, activeChatUsers: activeChatUsers }
  } = useChatManagerContext();

  const [isOnline, setIsOnline] = useState<boolean>(false);

  const selectedUserIdx = activeChatUsers.findIndex((u) => u.email === selectedUser?.userEmail);
  let isTemporarySelected: boolean = false;
  if (selectedUserIdx === -1) {
    isTemporarySelected = true;
  }
  const lastTrackedOnlineTimeStamp =
    selectedUserIdx === -1 ? null : activeChatUsers[selectedUserIdx].lastSignalTimestamp;

  const handleUpdatingIsOnline = useCallback((isOnline: boolean) => {
    setIsOnline(isOnline);
  }, []);

  return (
    <Grid
      item
      sx={{
        background: 'rgb(60, 133, 78)',
        height: '64px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 2,
        position: 'relative'
      }}
    >
      {selectedUser && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'center',
              gap: '4px'
            }}
          >
            <Typography variant="h4" color="white" fontWeight="normal">
              {selectedUser?.userName}
            </Typography>
            <ChatBoxHeaderOnlineIndicator
              chatConnection={chatConnection}
              selectedUser={selectedUser}
              lastTrackedOnlineTimeStamp={lastTrackedOnlineTimeStamp}
              isTemporarySelected={isTemporarySelected}
              key={selectedUser.userEmail}
              handleUpdatingIsOnline={handleUpdatingIsOnline}
            />
          </Box>

          <Avatar sx={{ bgcolor: deepOrange[500] }}>{selectedUser?.userName.toUpperCase()[0]}</Avatar>
        </>
      )}
      {selectedUser && !isOnline && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '-40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'max-content',
            zIndex: '25',
            px: 1.4,
            py: 0.6,
            backgroundColor: '#FDDA0D',
            borderRadius: '14px'
          }}
        >
          <Typography variant="h5" color="#8B4000">
            The user is offline! Consider using <a href={`mailto:${selectedUser?.userEmail}`}>this email</a> for
            contact.
          </Typography>
        </Box>
      )}
      {!selectedUser && <></>}
    </Grid>
  );
};

export default ChatBoxHeader;
