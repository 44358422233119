import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

type UserCardItemOnlineIndicatorProps = {
  lastTimestamp: number | null;
};

const UserCardItemOnlineIndicator = ({ lastTimestamp }: UserCardItemOnlineIndicatorProps) => {
  const initStatus = lastTimestamp && new Date().getTime() - lastTimestamp < 90000 ? true : false;
  const [isOnline, setIsOnline] = useState<boolean>(initStatus);

  useEffect(() => {
    if (lastTimestamp && new Date().getTime() - lastTimestamp < 90000) {
      setIsOnline(true);

      const timeout = setTimeout(() => {
        setIsOnline(false);
      }, 90000);
      return () => {
        clearTimeout(timeout);
        setIsOnline(false);
      };
    }
  }, [lastTimestamp]);

  if (isOnline) {
    return (
      <Box
        component="div"
        sx={{
          border: '3px solid #fff',
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          backgroundColor: '#4AAC5F',
          position: 'absolute',
          bottom: '2px',
          right: '-2px'
        }}
      ></Box>
    );
  }
  return <></>;
};

export default UserCardItemOnlineIndicator;
