export interface RoleMapping {
  [key: string]: string;
}
export const Roles = {
  Admin: 'Admin',
  Manager: 'Manager',
  Customer: 'Customer',
  Engineer: 'Engineer'
};

export const roleMapping: RoleMapping = {
  [Roles.Admin]: 'Admin',
  [Roles.Manager]: 'Manager',
  [Roles.Customer]: 'Customer',
  [Roles.Engineer]: 'Engineer'
};

export default roleMapping;
