import { useEffect, useMemo } from 'react';

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { getUnixTime } from 'date-fns';
import { Outlet } from 'react-router';
import { Navigate, useNavigate } from 'react-router-dom';

import { authClient } from 'apis';
import { RefreshTokenCommand } from 'apis/nswag';

import { useAppContext } from 'app/AppContext';
import { ANONYMOUS_ROUTES } from 'app/AppRoutes';
import ChatManager from 'app/components/chat';
import Footer from 'app/components/footer/Footer';
import AdminHeader from 'app/components/header/AdminHeader';
import UserAppHeader from 'app/components/header/UserAppHeader';

import { beforeTokenExpiryTimeInSecond } from 'common/consts/configs';
import roleMapping from 'common/consts/roles';
import useCurrentAdminAppRoute from 'common/hooks/useCurrentAdminAppRoute';

import GlobalDialog from 'components/Dialogs/GlobalDialog';
import GlobalAlert from 'components/GlobalAlert';

import { AuthActionTypes } from 'features/auth/login-reducer';
import { getDefaultPage, getSavedAuthInfoFromLocalStorage, getTokenExp, isTokenExpired } from 'features/auth/utils';

import { appThemes } from 'styles/theme';
import themeTypography from 'styles/typography';

interface Props {
  adminApp?: boolean;
}

const ProtectedRouteLayout = ({ adminApp }: Props) => {
  const navigate = useNavigate();
  const currentRoute = useCurrentAdminAppRoute();

  const {
    dispatch,
    state: {
      auth: { info: authInfo }
    }
  } = useAppContext();
  const tokenExp = getTokenExp(authInfo?.accessToken);
  const remainSecondsBeforeExpire = useMemo(
    () => (tokenExp ? tokenExp - beforeTokenExpiryTimeInSecond - getUnixTime(new Date()) : 0),
    [tokenExp]
  );

  const role = useMemo(() => {
    const role = roleMapping[authInfo?.role || ''];
    return role;
  }, [authInfo?.role]);

  useEffect(() => {
    const refreshToken = async (request: RefreshTokenCommand) => {
      try {
        const refreshTokenResult = await authClient.refreshToken(request);
        dispatch({ type: AuthActionTypes.AUTH_INFO_SET, payload: refreshTokenResult });
      } catch {
        dispatch({ type: AuthActionTypes.AUTH_LOG_OUT });
        navigate(`/${ANONYMOUS_ROUTES.Login.path}`);
      }
    };

    if (!!authInfo?.refreshToken && (tokenExp || remainSecondsBeforeExpire < 0)) {
      const timer = setTimeout(() => {
        const savedAuthInfo = getSavedAuthInfoFromLocalStorage();
        refreshToken({
          accessToken: savedAuthInfo?.accessToken,
          refreshToken: savedAuthInfo?.refreshToken
        });
      }, remainSecondsBeforeExpire * 1000);

      return () => {
        clearTimeout(timer);
      };
    }

    const isLoggedIn = !!authInfo && authInfo?.accessToken && !isTokenExpired(authInfo?.accessToken);

    if (!isLoggedIn) {
      navigate('/', { replace: true });
    }
  }, [authInfo, navigate, dispatch, remainSecondsBeforeExpire, tokenExp]);
  const rootThemes = appThemes();
  const adminThemes = createTheme({ ...rootThemes, typography: { ...themeTypography(), fontFamily: 'Roboto' } });

  if (
    currentRoute !== null &&
    currentRoute.allowedRoles &&
    currentRoute.allowedRoles.length > 0 &&
    !currentRoute.allowedRoles.includes(authInfo?.role || '')
  ) {
    const { address } = getDefaultPage(authInfo?.role);
    return <Navigate to={address} replace />;
  }

  return (
    <ThemeProvider theme={adminThemes}>
      <GlobalDialog />
      <GlobalAlert />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {adminApp ? <AdminHeader /> : <UserAppHeader />}
        <Box component="main" sx={{ flexGrow: 1, paddingTop: '64px', paddingBottom: '60px', display: 'flex' }}>
          <Outlet />
        </Box>
        {role !== 'Customer' && <ChatManager />}
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default ProtectedRouteLayout;
