import { useCallback, useRef } from 'react';

import { Avatar, Box, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { t } from 'i18next';

import { useChatManagerContext } from '../context';
import { IChatMessage } from '../types';
import UserCardItemOnlineIndicator from './UserCardItemOnlineIndicator';

type UserCardItemProps = {
  userEmail: string;
  userName: string;
  lastMessage: IChatMessage;
  unreadMessage?: number;
};

const UserCardItem = ({ userEmail, userName, lastMessage, unreadMessage }: UserCardItemProps) => {
  const {
    state: { selectedUser: selectedUser, activeChatUsers: activeChatUsers },
    dispatchChatManager
  } = useChatManagerContext();

  const isSelected = selectedUser?.userEmail == userEmail;

  const lastTimestampRef = useRef<number | null>(null);

  const activeUser = activeChatUsers.find((u) => u.email === userEmail);
  if (activeUser?.lastSignalTimestamp) {
    if (!lastTimestampRef.current || activeUser?.lastSignalTimestamp > lastTimestampRef.current) {
      lastTimestampRef.current = activeUser?.lastSignalTimestamp;
    }
  }

  const handleOnClicked = useCallback(() => {
    dispatchChatManager({
      type: 'chatManager.selectingUser',
      payload: { userEmail, userName }
    });
  }, [dispatchChatManager, userEmail, userName]);
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={1}
      onClick={handleOnClicked}
      sx={{
        paddingLeft: 1,
        py: 1,
        backgroundColor: isSelected ? '#0f633f' : 'none',
        borderRadius: isSelected ? '12px' : 'none',
        '&:hover': {
          backgroundColor: isSelected ? '#0f644f' : '#0f520d',
          borderRadius: '12px',
          cursor: 'pointer'
        }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Avatar sx={{ bgcolor: yellow[500], width: 18, height: 18, px: 2, py: 2 }}>{userName.toUpperCase()[0]}</Avatar>
        <UserCardItemOnlineIndicator lastTimestamp={lastTimestampRef.current} />
      </Box>

      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: '192px', flexGrow: 1 }}
      >
        <Box
          component="div"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.2,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            paddingRight: 2
          }}
        >
          <Typography
            variant="h4"
            color="white"
            fontWeight={unreadMessage || 0 > 0 ? 'bold' : '400'}
            noWrap
            maxWidth="164px"
          >
            {userName}
          </Typography>
          <Typography
            variant="h5"
            color="white"
            fontWeight={unreadMessage || 0 > 0 ? 'bold' : 'light'}
            noWrap
            maxWidth="164px"
          >
            {lastMessage.side === 'right' ? `${t('chat.you')}: ${lastMessage.message}` : lastMessage.message}
          </Typography>
        </Box>
        {unreadMessage || 0 > 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: 'red'
              }}
            >
              <Typography color="white" fontSize="11px" fontWeight="bold">
                {unreadMessage}
              </Typography>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default UserCardItem;
