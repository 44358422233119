import { Dispatch, PropsWithChildren, createContext, useContext, useMemo, useReducer } from 'react';

import { ChatManagerActionType, chatManagerReducer, initChatManagerStateState } from './reducer';
import { IChatManagerState } from './types';

const ChatManagerContext = createContext<{
  state: IChatManagerState;
  dispatchChatManager: Dispatch<ChatManagerActionType>;
}>({
  state: initChatManagerStateState,
  dispatchChatManager: () => null
});

interface Props extends PropsWithChildren {
  initialState?: Partial<ChatManagerActionType>;
}

const ChatManagerProvider = ({ children, initialState = {} }: Props) => {
  const [state, dispatchChatManager] = useReducer(chatManagerReducer, {
    ...initChatManagerStateState,
    ...initialState
  });
  const value = useMemo(() => ({ state, dispatchChatManager }), [state]);

  return <ChatManagerContext.Provider value={value}>{children}</ChatManagerContext.Provider>;
};

const useChatManagerContext = () => useContext(ChatManagerContext);

export { ChatManagerProvider, useChatManagerContext };
