import * as React from 'react';

import { Input as BaseInput, InputProps, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';

export const Input = React.forwardRef(function CustomInput(
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { slots, ...other } = props;
  return (
    <BaseInput
      autoFocus
      slots={{
        root: InputRoot,
        input: InputElement,
        ...slots
      }}
      {...other}
      ref={ref}
    />
  );
});

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027'
};

const InputRoot = styled('div')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  border-radius: 15px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  border: 0.6px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 8px 8px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  display: flex;
  align-items: center;
  justify-content: center;

  &.${inputClasses.focused} {
    border-color: ${grey[400]};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const InputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`
);
