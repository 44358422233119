import { appConfigs } from 'common/consts/configs';

import axiosInstance from './https';
import * as nswag from './nswag';

const userClient = new nswag.UserClient(appConfigs.apiUrl, axiosInstance);
const authClient = new nswag.AuthClient(appConfigs.apiUrl, axiosInstance);
const lessonClient = new nswag.LessonClient(appConfigs.apiUrl, axiosInstance);
const lessonTopicClient = new nswag.LessonTopicClient(appConfigs.apiUrl, axiosInstance);
const lessonDocumentClient = new nswag.LessonDocumentClient(appConfigs.apiUrl, axiosInstance);
const workOrderClient = new nswag.WorkOrderClient(appConfigs.apiUrl, axiosInstance);
const workOrderDocumentClient = new nswag.WorkOrderDocumentClient(appConfigs.apiUrl, axiosInstance);
const rankClient = new nswag.RankClient(appConfigs.apiUrl, axiosInstance);
const chatClient = new nswag.ChatClient(appConfigs.apiUrl, axiosInstance);
const computerClient = new nswag.ComputerClient(appConfigs.apiUrl, axiosInstance);

export {
  authClient,
  chatClient,
  lessonClient,
  lessonDocumentClient,
  lessonTopicClient,
  rankClient,
  userClient,
  workOrderClient,
  computerClient,
  workOrderDocumentClient
};
