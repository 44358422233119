import { Box, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        height: '60px',
        background: '#000',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        py: '18px',
        zIndex: 1
      }}
    >
      <Typography sx={{ color: '#FFF', textAlign: 'center' }}>Copyright © 2023 Yamazaki M&E design Co.,Ltd.</Typography>
    </Box>
  );
}
