import { useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { useChatManagerContext } from '../context';

const ChatTypingIndicator = () => {
  const {
    state: { selectedUser: selectedUser, typingUsers }
  } = useChatManagerContext();

  const [isTyping, setIsTyping] = useState(false);
  const lastTypingTimestampRef = useRef(new Date().getTime());

  useEffect(() => {
    if (typingUsers) {
      const thisUser = typingUsers.find((u) => u.userEmail === selectedUser?.userEmail);
      if (thisUser?.typingTimestamp && thisUser?.typingTimestamp - lastTypingTimestampRef.current > 1000) {
        setIsTyping(true);
        lastTypingTimestampRef.current = thisUser?.typingTimestamp;

        const timeout = window.setTimeout(() => {
          setIsTyping(false);
        }, 3000);
        return () => {
          window.clearTimeout(timeout);
          setIsTyping(false);
        };
      }
    }
  }, [selectedUser?.userEmail, typingUsers]);

  if (isTyping) {
    return (
      <Box
        component="div"
        sx={[
          {
            borderRadius: '12px',
            backgroundColor: '#f0f5f5',
            py: 1,
            px: 1,
            mt: 0.2,
            width: '50px',
            height: '28px',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }
        ]}
      >
        <Typography
          className="dot-falling"
          fontSize="12px"
          color="black"
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></Typography>
      </Box>
    );
  }
  return <></>;
};

export default ChatTypingIndicator;
