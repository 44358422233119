import { getUnixTime } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { LoginResponse } from 'apis/nswag';

import { Roles } from 'common/consts/roles';

const loginInfoKey = 'loginInfo';

export const getSavedAuthInfoFromLocalStorage = () => {
  const loginInfo: LoginResponse =
    window.localStorage.getItem(loginInfoKey) && JSON.parse(window.localStorage.getItem(loginInfoKey) ?? '');

  return loginInfo
    ? {
        ...loginInfo
      }
    : null;
};

export const saveAuthInfoToLocalStorage = (data: LoginResponse) => {
  window.localStorage.setItem(loginInfoKey, JSON.stringify(data));
};

export const removeAuthInfoFromLocalStorage = () => {
  window.localStorage.removeItem(loginInfoKey);
};

export const isTokenExpired = (accessToken = '') => {
  if (!accessToken) return false;

  const { exp } = jwtDecode<JwtPayload>(accessToken);
  if (!exp) return false;

  const isExpired = exp < getUnixTime(new Date());

  return isExpired;
};

export const getTokenExp = (accessToken = '') => {
  if (!accessToken) return 0;

  const { exp } = jwtDecode<JwtPayload>(accessToken);
  if (!exp) return 0;

  return exp;
};

export const getDefaultPage = (userRole = '') => {
  switch (userRole) {
    case Roles.Admin:
      return {
        address: '/admin/users',
        activeTab: 0
      };

    case Roles.Manager:
      return {
        address: '/admin/orders',
        activeTab: 1
      };

    case Roles.Customer:
      return {
        address: '/orders',
        activeTab: 0
      };

    case Roles.Engineer:
      return {
        address: '/working-history',
        activeTab: 0
      };

    default:
      return {
        address: '/',
        activeTab: 0
      };
  }
};
