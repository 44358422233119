import { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Grow, IconButton } from '@mui/material';

import { useChatManagerContext } from '../context';
import ChatWaiterIcon from './ChatWaiterIcon';

type ChatPopupIconProps = {
  onPopupIconClick: (isOpen: boolean) => void;
};

const ChatPopupIcon = ({ onPopupIconClick }: ChatPopupIconProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { state } = useChatManagerContext();
  const handleClicked = useCallback(() => {
    setIsOpen((o) => !o);
    onPopupIconClick(!isOpen);
  }, [onPopupIconClick, isOpen]);

  return (
    <IconButton aria-label="chat" onClick={handleClicked}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '64px',
          height: '64px',
          borderRadius: '50%',
          backgroundColor: 'rgba(247, 249, 252)',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
        }}
      >
        {isOpen ? (
          <Grow in={isOpen} style={{ transitionDelay: isOpen ? '50ms' : '0ms' }}>
            <CloseIcon color="action" sx={{ minHeight: '32px', minWidth: '32px' }} />
          </Grow>
        ) : (
          <ChatWaiterIcon unReadConversations={state.unReadConversations} />
        )}
      </Box>
    </IconButton>
  );
};

export default ChatPopupIcon;
