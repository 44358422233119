import { memo, useMemo } from 'react';

import { Tabs } from '@mui/material';

import { useAppContext } from 'app/AppContext';
import { AppActionTypes } from 'app/reducers/appReducer';

import { Roles } from 'common/consts/roles';

import LinkTab from '../LinkTab';

const AdminNavTabs = () => {
  const {
    dispatch,
    state: {
      app: { activeTab: activeTab }
    }
  } = useAppContext();

  const {
    state: {
      auth: { info }
    }
  } = useAppContext();

  const navItems = useMemo(() => {
    if (info?.role === Roles.Admin) {
      return [
        { label: 'Users', to: '/admin/users' },
        { label: 'Lessons', to: '/admin/lessons' },
        { label: 'Orders', to: '/admin/orders' },
        { label: 'Rankings', to: '/admin/ranks' },
        { label: 'Computers', to: '/admin/computers' }
      ];
    }

    if (info?.role === Roles.Manager) {
      return [
        { label: 'Lessons', to: '/admin/lessons' },
        { label: 'Orders', to: '/admin/orders' },
        { label: 'Rankings', to: '/admin/ranks' },
        { label: 'Computers', to: '/admin/computers' }
      ];
    }

    return [];
  }, [info?.role]);

  const handleChangeTab = (e: any, value: number) => {
    dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: value });
  };

  return (
    <>
      <Tabs value={activeTab} aria-label="Admin nav tabs" textColor="inherit" onChange={handleChangeTab}>
        {navItems.map((item) => (
          <LinkTab key={item.to} label={item.label} to={item.to} />
        ))}
      </Tabs>
    </>
  );
};

export default memo(AdminNavTabs);
