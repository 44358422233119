import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { HubConnection } from '@microsoft/signalr';
import { ArrowCircleDown } from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, Slide, Typography } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

import { chatClient } from 'apis';

import { useAppContext } from 'app/AppContext';

import { useChatManagerContext } from '../context';
import './ChatBox.css';
import ChatBoxHeader from './ChatBoxHeader';
import ChatInput from './ChatInput';
import ChatNavigationBar from './ChatNavigationBar';
import ChatTypingIndicator from './ChatTypingIndicator';
import Message from './messages/Message';

type IChatBoxProps = {
  isOpen: boolean;
  chatConnection: HubConnection | null;
};

const ChatBox = ({ isOpen, chatConnection }: IChatBoxProps) => {
  const display = isOpen ? 'flex' : 'none';
  const {
    state: {
      auth: { info }
    }
  } = useAppContext();

  const {
    state: { conversations: conversations, selectedUser: selectedUser, lastUpdatedUser },
    dispatchChatManager
  } = useChatManagerContext();

  const messages = useMemo(() => {
    if (!conversations || !selectedUser) {
      return [];
    }
    return conversations.find((con) => con.email == selectedUser.userEmail)?.messages;
  }, [conversations, selectedUser]);
  const lastUserEmailRef = useRef('');
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const chatBottomRef = useRef<HTMLDivElement>(null);
  const [isShowScrollToBottom, setIsShowScrollToBottom] = useState(false);

  const handleScrollToBottom = useCallback((isInstant?: boolean) => {
    if (chatBottomRef.current && chatBoxRef.current) {
      if (isInstant) {
        chatBottomRef.current.scrollIntoView(true);
      } else {
        chatBottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const handleScrollChatBox = useCallback(() => {
    if (chatBoxRef.current) {
      const scrollTop = chatBoxRef.current.scrollTop;
      const clientHeight = chatBoxRef.current.clientHeight;
      const scrollHeight = chatBoxRef.current.scrollHeight;

      const bottomPosition = scrollHeight - clientHeight - scrollTop;

      const updated = bottomPosition > 75;

      if (updated !== isShowScrollToBottom) {
        setIsShowScrollToBottom(updated);
      }
    }
  }, [isShowScrollToBottom]);

  useEffect(() => {
    handleScrollToBottom();
  }, [handleScrollToBottom]);

  useEffect(() => {
    if (selectedUser) {
      const lastMessage = conversations.find((con) => con.email === selectedUser.userEmail)?.lastMessage;
      if (lastUserEmailRef.current !== selectedUser.userEmail) {
        handleScrollToBottom(true);
        lastUserEmailRef.current = selectedUser.userEmail;
      } else if (lastMessage?.side == 'right' && lastUpdatedUser == selectedUser.userEmail) {
        handleScrollToBottom();
      } else if (!isShowScrollToBottom && lastUpdatedUser == selectedUser.userEmail) {
        handleScrollToBottom();
      }
    }
  }, [conversations, selectedUser, handleScrollToBottom, isShowScrollToBottom, lastUpdatedUser]);

  const handleOnResendMessage = useCallback(
    async (id: string, message: string) => {
      if (selectedUser && info?.email) {
        dispatchChatManager({
          type: 'chatManager.messageResending',
          payload: {
            id: id,
            fromUserEmail: info.email,
            message: message,
            toUserEmail: selectedUser.userEmail,
            toUserName: selectedUser.userName
          }
        });
        try {
          await chatClient.sendMessage(id, selectedUser.userEmail, message);
        } catch (error) {
          dispatchChatManager({
            type: 'chatManager.markErrorSendingMessage',
            payload: {
              id: id,
              toUserEmail: selectedUser.userEmail,
              error: 'Failed to send message'
            }
          });
        }
      }
    },
    [dispatchChatManager, info?.email, selectedUser]
  );

  return (
    <Box
      id="chatbox"
      sx={{
        display: { display },
        height: '520px',
        width: '760px',
        background: '#fff',
        position: 'relative',
        flex: 1,
        borderRadius: '10px',
        top: -8,
        left: 8,
        boxShadow: 'rgba(0, 0, 0, 0.35) 4px 10px 24px',
        overflow: 'hidden'
      }}
    >
      <Grid container>
        <Grid
          item
          xs={4}
          sx={{
            background: 'rgb(61, 168, 88)'
          }}
          id="navigation"
        >
          <Grid
            container
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid
              item
              sx={{
                background: 'rgb(21, 158, 55)',
                width: '100%',
                height: '64px',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                pl: 2
              }}
              id="navigation-header"
            >
              <Grid container direction="row" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Grid item xs={2} display="flex" justifyContent="flex-start">
                  <Avatar sx={{ bgcolor: deepPurple[500] }}>A</Avatar>
                </Grid>
                <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
                  <Typography variant="h4" color="white">
                    {`${info?.firstName}.${info?.lastName}`}
                  </Typography>
                  <Typography variant="h6" color="white" noWrap>
                    {info?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="navigation-bar" sx={{ maxHeight: '456px' }}>
              <ChatNavigationBar />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} id="chat-container">
          <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
            <ChatBoxHeader chatConnection={chatConnection} key={selectedUser?.userEmail} />
            <Grid item sx={{ height: '390px', position: 'relative' }}>
              {selectedUser ? (
                <Box
                  id="messagesContent"
                  component="div"
                  sx={{ height: '100%', width: '100%', overflowY: 'auto', px: 2, py: 1 }}
                  ref={chatBoxRef}
                  onScroll={handleScrollChatBox}
                >
                  {messages &&
                    messages.map((message) => (
                      <Message
                        id={message.id}
                        message={message.message}
                        dateTime={message.sentDate || new Date()}
                        side={message.side}
                        key={message.id}
                        error={message.error}
                        isSending={message.isSending}
                        handleOnResendMessage={handleOnResendMessage}
                      />
                    ))}
                  <ChatTypingIndicator />
                  <div ref={chatBottomRef}></div>
                  {isShowScrollToBottom && (
                    <Slide
                      direction="up"
                      in={isShowScrollToBottom}
                      mountOnEnter
                      unmountOnExit
                      container={chatBoxRef.current}
                    >
                      <Box sx={{ position: 'absolute', bottom: '15px', left: '50%', transform: 'translateX(-50%)' }}>
                        <IconButton onClick={() => handleScrollToBottom()}>
                          <ArrowCircleDown sx={{ color: '#3c8c41' }} />
                        </IconButton>
                      </Box>
                    </Slide>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ flexGrow: 1, textAlign: 'center' }}>Select a chat to begin</Box>
                </Box>
              )}
            </Grid>
            <Grid
              item
              sx={{ height: '65px', display: 'flex', justifyContent: 'center', flexDirection: 'column', px: 1 }}
              zIndex={1}
            >
              <ChatInput disabled={!selectedUser} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatBox;
