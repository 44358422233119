import { useCallback, useEffect, useRef, useState } from 'react';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Box, Grow } from '@mui/material';
import useSound from 'use-sound';

import { chatClient, userClient } from 'apis';

import { useAppContext } from 'app/AppContext';

import { appConfigs, chatHub } from 'common/consts/configs';

import { getSavedAuthInfoFromLocalStorage } from 'features/auth/utils';

import ChatBox from './components/ChatBox';
import ChatPopupIcon from './components/ChatPopupIcon';
import { ChatManagerProvider, useChatManagerContext } from './context';

let fetchedUsers: { email: string; userName: string }[] = [];

const buzzSoundUrl = `${process.env.PUBLIC_URL}/sounds/buzz.mp3`;

const ChatContainer = () => {
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [play] = useSound(buzzSoundUrl);
  const {
    state: {
      auth: { info }
    }
  } = useAppContext();

  const onPopupIconClicked = useCallback((isOpen: boolean) => {
    setIsChatBoxOpen(isOpen);
  }, []);

  const { state, dispatchChatManager } = useChatManagerContext();
  const [connection, setConnection] = useState<null | HubConnection>(null);

  const lastReceivedMessageIdRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    const onlineStatusInterval = setInterval(() => {
      chatClient.sendOnlineSignal(new Date().getTime(), undefined);
    }, 60000);

    return () => {
      clearInterval(onlineStatusInterval);
    };
  }, []);

  useEffect(() => {
    if (state.lastReceivedMessageId && state.lastReceivedMessageId !== lastReceivedMessageIdRef.current) {
      lastReceivedMessageIdRef.current = state.lastReceivedMessageId;
      play();
    }
  }, [state.lastReceivedMessageId, play]);

  useEffect(() => {
    const savedAuthInfo = getSavedAuthInfoFromLocalStorage();
    const connect = new HubConnectionBuilder()
      .withUrl(`${appConfigs.apiUrl}/${chatHub}`, { accessTokenFactory: () => savedAuthInfo?.accessToken || '' })
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection && connection.state === 'Disconnected') {
      connection
        .start()
        .then(() => {
          connection.on('ReceiveMessage', async (id, textMessage, email, date) => {
            const existingUser = fetchedUsers.find((u) => u.email === email);
            let userName = existingUser?.userName || '';

            if (userName === '') {
              userName = await userClient.getUsernameByEmail(email, undefined);
              fetchedUsers = [...fetchedUsers, { email: email, userName: userName }];
            }
            dispatchChatManager({
              type: 'chatManager.messageReceived',
              payload: {
                id: id,
                message: textMessage,
                userName: userName,
                userEmail: email,
                receivedDate: date
              }
            });
          });
          connection.on('MessageSent', async (id, textMessage, toUserEmail, date) => {
            const existingUser = fetchedUsers.find((u) => u.email === toUserEmail);
            let userName = existingUser?.userName || '';

            if (userName === '') {
              userName = await userClient.getUsernameByEmail(toUserEmail, undefined);
              fetchedUsers = [...fetchedUsers, { email: toUserEmail, userName: userName }];
            }

            dispatchChatManager({
              type: 'chatManager.messageSent',
              payload: {
                fromUserEmail: info?.email || '',
                id: id,
                message: textMessage,
                sentDate: date,
                toUserEmail: toUserEmail,
                toUserName: userName
              }
            });
          });
          connection.on('IsTyping', (fromUserName) => {
            dispatchChatManager({
              type: 'chatManager.isTyping',
              payload: fromUserName
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => {
      if (connection && connection.state === 'Connected') {
        connection.stop();
      }
    };
  }, [connection, dispatchChatManager, info?.email]);

  const updateChatActiveUsers = useCallback(
    (email: string, timeStamp: number | null) => {
      dispatchChatManager({
        type: 'chatManager.updateChatActiveUsers',
        payload: {
          email: email,
          lastSignalTimestamp: timeStamp
        }
      });
    },
    [dispatchChatManager]
  );

  const getUserOnlineStatus = useCallback(
    async (email: string) => {
      const result = await userClient.getOnlineTimeStamp(email);
      updateChatActiveUsers(email, result !== 0 ? result : null);
    },
    [updateChatActiveUsers]
  );

  const subscribeForUserOnlineStatus = useCallback(
    (email: string) => {
      if (connection && connection.state === 'Connected') {
        connection.on(email, (timeStamp) => {
          updateChatActiveUsers(email, timeStamp);
        });
      }
    },
    [connection, updateChatActiveUsers]
  );

  useEffect(() => {
    if (state.activeChatUsers.length < 1) {
      state.conversations.forEach((con) => {
        getUserOnlineStatus(con.email);
        subscribeForUserOnlineStatus(con.email);
      });
    } else {
      state.conversations.forEach((con) => {
        const userIdx = state.activeChatUsers.findIndex((u) => u.email === con.email);
        if (userIdx == -1) {
          getUserOnlineStatus(con.email);
          subscribeForUserOnlineStatus(con.email);
        }
      });
    }
  }, [state.conversations, state.activeChatUsers, subscribeForUserOnlineStatus, getUserOnlineStatus]);
  return (
    <Box
      sx={{
        zIndex: 10,
        position: 'fixed',
        left: 8,
        bottom: 62
      }}
    >
      <Grow in={isChatBoxOpen} style={{ transformOrigin: 'bottom left' }} {...(isChatBoxOpen ? { timeout: 300 } : {})}>
        <Box>
          <ChatBox chatConnection={connection} isOpen={isChatBoxOpen} key={state.selectedUser?.userEmail} />
        </Box>
      </Grow>

      <ChatPopupIcon onPopupIconClick={onPopupIconClicked} />
    </Box>
  );
};

const ChatManager = () => {
  return (
    <ChatManagerProvider>
      <ChatContainer />
    </ChatManagerProvider>
  );
};

export default ChatManager;
