import { useCallback, useMemo } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import { format, isToday } from 'date-fns';

import MessageReSendAction from './MessageReSendAction';

type MessageProps = {
  id: string;
  message: string;
  dateTime: Date;
  side: 'left' | 'right';
  isSending?: boolean | undefined;
  error?: string | undefined;
  handleOnResendMessage: (id: string, message: string) => void;
};

const leftMessage = {
  placement: 'flex-start',
  backgroundColor: '#E3E6EA',
  onHoverBackGround: '#daebeb',
  textColor: 'black',
  toolTipPlacement: 'right'
};

const rightMessage = {
  placement: 'flex-end',
  backgroundColor: '#3c8c41',
  onHoverBackGround: '#5ba360',
  textColor: 'white',
  toolTipPlacement: 'left'
};

const Message = ({ id, message, dateTime, side, isSending, error, handleOnResendMessage }: MessageProps) => {
  const messageSide = useMemo(() => (side === 'left' ? leftMessage.placement : rightMessage.placement), [side]);
  const backgroundColor = useMemo(
    () => (side === 'left' ? leftMessage.backgroundColor : rightMessage.backgroundColor),
    [side]
  );

  const onHoverBackgroundColor = useMemo(
    () => (side === 'left' ? leftMessage.onHoverBackGround : rightMessage.onHoverBackGround),
    [side]
  );
  const textColor = useMemo(() => (side === 'left' ? leftMessage.textColor : rightMessage.textColor), [side]);

  const displayingTime: string = useMemo(() => {
    const time = new Date(dateTime);
    if (isToday(time)) {
      return format(time, 'p');
    }
    return format(time, 'ccc, cc MMM uuuu p');
  }, [dateTime]);

  const onOnResendMessage = useCallback(() => {
    handleOnResendMessage(id, message);
  }, [handleOnResendMessage, id, message]);

  return (
    <Box component="div" display="flex" justifyContent={messageSide} flexDirection="row" alignItems="center" gap="8px">
      {side === 'right' && (
        <MessageReSendAction isSending={isSending} error={error} handleOnResendClicked={onOnResendMessage} />
      )}
      <Tooltip title={displayingTime} placement={side === 'left' ? 'right' : 'left'}>
        <Box
          component="div"
          sx={[
            {
              borderRadius: '14px',
              backgroundColor: backgroundColor,
              py: 1,
              px: 1,
              mt: 0.2,
              maxWidth: '320px',
              width: 'fit-content'
            },
            () => ({
              '&:hover ': {
                backgroundColor: onHoverBackgroundColor
              }
            })
          ]}
        >
          <Typography
            fontSize="12px"
            sx={{ wordBreak: 'break-word' }}
            color={textColor}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {message}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Message;
