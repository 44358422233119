import { useEffect } from 'react';

import { Box, AppBar as MuiAppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'app/AppContext';
import { AppActionTypes } from 'app/reducers/appReducer';

import { Roles } from 'common/consts/roles';

import { getDefaultPage } from 'features/auth/utils';

export default function Header({ children }: React.PropsWithChildren) {
  const {
    dispatch,
    state: {
      auth: { info: authInfo },
      app: { activeTab: activeTab }
    }
  } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    switch (authInfo?.role) {
      case Roles.Admin: {
        let adminRoute = window.location.pathname.substring(1);
        const pathParts = adminRoute.split('/');
        if (pathParts.length >= 2) {
          adminRoute = pathParts[1];
        }
        switch (adminRoute) {
          case 'users':
            if (activeTab !== 0) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 0 });
            }
            break;
          case 'lessons':
            if (activeTab !== 1) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 1 });
            }
            break;
          case 'orders':
            if (activeTab !== 2) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 2 });
            }
            break;
          case 'ranks':
            if (activeTab !== 3) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 3 });
            }
            break;
          case 'computers':
            if (activeTab !== 4) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 4 });
            }
            break;
          default:
            break;
        }
        break;
      }
      case Roles.Manager: {
        let managerRoute = window.location.pathname.substring(1);
        const pathParts = managerRoute.split('/');
        if (pathParts.length >= 2) {
          managerRoute = pathParts[1];
        }
        switch (managerRoute) {
          case 'lessons':
            if (activeTab !== 0) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 0 });
            }
            break;
          case 'orders':
            if (activeTab !== 1) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 1 });
            }
            break;
          case 'ranks':
            if (activeTab !== 2) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 2 });
            }
            break;
          case 'computers':
            if (activeTab !== 3) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 3 });
            }
            break;
          default:
            break;
        }
        break;
      }
      case Roles.Engineer:
        switch (window.location.pathname) {
          case 'working-history':
            if (activeTab !== 0) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 0 });
            }
            break;
          case '/job-board':
            if (activeTab !== 1) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 1 });
            }
            break;
          case '/lessons':
            if (activeTab !== 2) {
              dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: 2 });
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [activeTab, authInfo?.role, dispatch]);

  const goHomePage = async () => {
    let defaultPage = '/';
    let defaultTab = 0;
    if (authInfo) {
      const { address, activeTab } = getDefaultPage(authInfo.role);
      defaultPage = address;
      defaultTab = activeTab;
    }
    navigate(defaultPage);
    dispatch({ type: AppActionTypes.SET_ACTIVE_TAB, payload: defaultTab });
  };

  return (
    <MuiAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#2C8D24' }}>
      <Toolbar
        sx={{
          paddingLeft: {
            xs: '8px',
            md: '60px'
          },
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <img src="/images/yamazaki-logo.png" height="36px" alt="Yamazaki" onClick={() => goHomePage()} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>{children}</Box>
      </Toolbar>
    </MuiAppBar>
  );
}
