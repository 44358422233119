import * as React from 'react';

import { Input as BaseInput, InputProps, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';

export const SearchInput = React.forwardRef(function CustomInput(
  props: InputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { slots, ...other } = props;
  return (
    <BaseInput
      slots={{
        root: InputRoot,
        input: InputElement,
        ...slots
      }}
      {...other}
      ref={ref}
    />
  );
});

const green = {
  50: '#00e500',
  100: '#00cc00',
  200: '#00b200',
  300: '#009900',
  400: '#007f00',
  500: '#006600',
  600: '#004c00',
  700: '#003300',
  800: '#001900',
  900: '#000000'
};

const InputRoot = styled('div')(
  () => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  border-radius: 15px;
  color: #fff;
  background: #3c854e;
  display: flex;
  align-items: center;
  justify-content: center;

  &.${inputClasses.focused} {
    border-color: ${green[400]};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const InputElement = styled('input')(
  () => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: #fff;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
  ::placeholder {
    color: #fff;
  }
`
);
