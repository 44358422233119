import { useCallback, useRef, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { IconButton, InputAdornment } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { chatClient } from 'apis';

import { useAppContext } from 'app/AppContext';

import { useChatManagerContext } from '../context';
import { Input } from './CustomStyledInput';

type ChatInputProps = {
  disabled: boolean;
};

const ChatInput = ({ disabled }: ChatInputProps) => {
  const lastTypingTimestampRef = useRef(new Date().getTime());
  const [textMessage, setTextMessage] = useState('');
  const { state, dispatchChatManager } = useChatManagerContext();
  const {
    state: {
      auth: { info }
    }
  } = useAppContext();

  const handleOnInputChanged = useCallback(
    (e: any) => {
      setTextMessage(e.target.value);

      const currentInputTimestamp = new Date().getTime();

      if (currentInputTimestamp - lastTypingTimestampRef.current > 1000) {
        lastTypingTimestampRef.current = currentInputTimestamp;
        chatClient.typing(state.selectedUser?.userEmail);
      }
    },
    [state.selectedUser?.userEmail]
  );

  const handleSendMessage = useCallback(async () => {
    if (textMessage.length > 0) {
      const receiverEmail = state.selectedUser?.userEmail;
      const senderEmail = info?.email;
      if (receiverEmail && senderEmail) {
        const randomId = uuid();
        dispatchChatManager({
          type: 'chatManager.messageSending',
          payload: {
            fromUserEmail: senderEmail,
            id: randomId,
            message: textMessage,
            toUserEmail: receiverEmail,
            toUserName: state.selectedUser?.userName || ''
          }
        });

        try {
          await chatClient.sendMessage(randomId, receiverEmail, textMessage);
        } catch (error) {
          dispatchChatManager({
            type: 'chatManager.markErrorSendingMessage',
            payload: {
              id: randomId,
              toUserEmail: receiverEmail,
              error: 'Failed to send message'
            }
          });
        }
      }
    }
  }, [dispatchChatManager, info?.email, state.selectedUser?.userEmail, state.selectedUser?.userName, textMessage]);

  const handlerOnChatInputFocus = useCallback(() => {
    if (state.selectedUser?.userEmail) {
      const con = state.conversations.find((con) => con.email === state.selectedUser?.userEmail);
      if (con?.unreadMessages || 0 > 0) {
        dispatchChatManager({ type: 'chatManager.markReadConversation', payload: state.selectedUser?.userEmail });
      }
    }
  }, [dispatchChatManager, state.conversations, state.selectedUser?.userEmail]);
  return (
    <Input
      id="chat-input"
      autoFocus
      value={textMessage}
      onKeyUp={(event) => {
        if (event.key == 'Enter') {
          handleSendMessage();
          setTextMessage('');
        }
      }}
      onChange={handleOnInputChanged}
      onFocus={() => {
        handlerOnChatInputFocus();
      }}
      disabled={disabled}
      placeholder="Type something..."
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            id="send-btn"
            disabled={disabled || textMessage.length < 1}
            onClick={() => {
              handleSendMessage();
              setTextMessage('');
            }}
          >
            <SendIcon sx={{ color: textMessage.length < 1 ? 'grey' : '#3c8c41' }} />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default ChatInput;
